<template>
  <div class="resetPassword">
    <ResetPasswordComponent />
  </div>
</template>

<script>
// @ is an alias to /src
import ResetPasswordComponent from '@/components/auth/ResetPasswordComponent.vue'

export default {
  name: 'ResetPasswordView',
  components: {
    ResetPasswordComponent
  }
}
</script>
