import { mapActions } from 'vuex'
import { ResetPasswordType, SnackbarStyle } from '@/constants/constants'
import store from '@/store/store'
import authApi from '@/api/auth.api'
import i18n from '@/i18n'

export default {
  name: 'ResetPasswordComponent',
  data () {
    return {
      username: '',
      newPassword: '',
      repeatNewPassword: '',
      jwtQuery: '',
      resetPasswordType: ResetPasswordType.USER_RESET,
      decodedJwt: {},
      submitted: false,
      showNewPassword: false,
      showRepeatNewPassword: false,
      validForm: false,
      rules: {
        required: value => !!value || `${i18n.t('required')}`,
        min: (value, minValue) => value.length >= minValue || `${i18n.t('min', { min: minValue })}`,
        samePassword: (password, repeatPassword) => password === repeatPassword || `${i18n.t('resetPassword.notEqualsRepeatPassword')}`,
        notUsername: (password, username) => {
          const searchText = username.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&')
          return ((password.match(new RegExp(searchText, 'i')) == null) || `${i18n.t('resetPassword.containsUsername')}`)
        }
      }
    }
  },
  mounted () {
    // Setea el lenguaje de los componentes de Vuetify
    this.$vuetify.lang.current = this.$i18n.locale
    this.jwtQuery = this.$route.params.token
    this.resetPasswordType = this.$route.meta.type
    if (!store.getters['auth/getJwt']) {
      store.commit('auth/commitJwt', { jwt: this.jwtQuery })
    }
    authApi.validateResetPasswordToken(this.jwtQuery).then(validationResponse => {
      this.username = validationResponse.data.username
    }).catch(() => {
      setTimeout(() => {
        this.$router.push('/').catch(err => {
          console.log(err)
        })
      }, 6000)
    })
  },
  computed: {
    formTitleLabel () {
      switch (this.resetPasswordType) {
        case ResetPasswordType.USER_RESET:
          return this.$t('resetPassword.userReset')
        case ResetPasswordType.NEXT_LOGIN:
          return this.$t('resetPassword.nextLogin')
        case ResetPasswordType.EXPIRATION:
          return this.$t('resetPassword.expiration')
        default:
          return this.$t('resetPassword.userReset')
      }
    }
  },
  methods: {
    ...mapActions({
      'showSnackbar': 'snackbar/showSnackbar',
      'closeSnackbar': 'snackbar/closeSnackbar'
    }),
    async submit () {
      this.submitted = true
      const body = { username: '', password: '', repeatPassword: '', resetPasswordType: '' }
      body.username = this.username
      body.password = this.newPassword
      body.repeatPassword = this.repeatNewPassword
      body.resetPasswordType = this.resetPasswordType
      authApi.resetPassword(body).then(() => {
        this.showSnackbar({ visible: true, text: this.$t('resetPassword.successfulPasswordReset'), timeout: 6000, style: SnackbarStyle.SUCCESS })
        setTimeout(() => {
          this.$router.push('/').catch(err => {
            console.log(err)
          })
        }, 6000)
      }).catch(() => {
        this.submitted = false
      })
    }
  }
}
